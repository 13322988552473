import * as React from 'react';

const ModalIconConstructor = React.memo(() => {
  return (
    <svg
      width="156"
      style={{ position: 'absolute', top: '-86px', left: '173px' }}
      height="156"
      viewBox="0 0 156 156"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle opacity="0.3" cx="78" cy="78" r="78" fill="#0D4FB2" />
      <ellipse opacity="0.3" cx="78.5" cy="78" rx="67.5" ry="68" fill="#3570C9" />
      <path
        d="M145 33.713V130.287C145 131.785 143.792 133 142.304 133H13.6962C12.2078 133 11 131.785 11 130.287V33.713C11 32.2153 12.2078 31 13.6962 31H142.304C143.792 31 145 32.2153 145 33.713Z"
        fill="white"
      />
      <path d="M11 48H145V83H11V48Z" fill="#F0F7FF" />
      <path d="M11 48H85V83H11V48Z" fill="#DDE4EA" />
      <path
        d="M145 33.7127V130.287C145 131.785 144.114 133 143.022 133H136C141 130.5 140 124.155 140 99C140 72.3355 140 41.5 140 31L143.022 31C144.114 30.9997 145 32.2151 145 33.7127Z"
        fill="#DDE4EA"
      />
      <path d="M145 48V83H140C140 66.898 140 58.0679 140 48H145Z" fill="#A5B3C5" />
      <path
        d="M21.797 41.8344H21.7667C20.6499 41.8344 19.7446 40.9291 19.7446 39.8123C19.7446 38.6956 20.6499 37.7903 21.7667 37.7903H21.797C22.9138 37.7903 23.8191 38.6956 23.8191 39.8123C23.8191 40.9288 22.9138 41.8344 21.797 41.8344Z"
        fill="#A5B3C5"
      />
      <path
        d="M27.902 41.8344H27.8716C26.7549 41.8344 25.8496 40.9291 25.8496 39.8123C25.8496 38.6956 26.7549 37.7903 27.8716 37.7903H27.902C29.0188 37.7903 29.924 38.6956 29.924 39.8123C29.924 40.9288 29.0188 41.8344 27.902 41.8344Z"
        fill="#A5B3C5"
      />
      <path
        d="M34.007 41.8344H33.9766C32.8599 41.8344 31.9546 40.9291 31.9546 39.8123C31.9546 38.6956 32.8599 37.7903 33.9766 37.7903H34.007C35.1237 37.7903 36.029 38.6956 36.029 39.8123C36.0293 40.9288 35.124 41.8344 34.007 41.8344Z"
        fill="#A5B3C5"
      />
      <path
        d="M128.785 41.8344H125.022C123.905 41.8344 123 40.9291 123 39.8123C123 38.6956 123.905 37.7903 125.022 37.7903H128.785C129.902 37.7903 130.807 38.6956 130.807 39.8123C130.807 40.9288 129.902 41.8344 128.785 41.8344Z"
        fill="#A5B3C5"
      />
      <path
        d="M44.8471 55.6418V76.1328C44.8471 77.6212 43.6392 78.829 42.1508 78.829H21.6601C20.1717 78.829 18.9639 77.6212 18.9639 76.1328V55.6418C18.9639 54.1534 20.1717 52.9456 21.6601 52.9456H42.1511C43.6392 52.9458 44.8471 54.1537 44.8471 55.6418Z"
        fill="#3570C9"
      />
      <path
        d="M44.847 55.6418V76.1328C44.847 77.6212 43.6391 78.829 42.1507 78.829H31.6113C39.0664 70.336 39.5705 59.4893 39.0125 52.9458H42.1507C43.6391 52.9458 44.847 54.1536 44.847 55.6418Z"
        fill="#0D4FB2"
      />
      <path
        d="M90.9052 118.324H65.022C63.9053 118.324 63 117.418 63 116.302C63 115.185 63.9053 114.28 65.022 114.28H90.9052C92.022 114.28 92.9273 115.185 92.9273 116.302C92.9275 117.418 92.0223 118.324 90.9052 118.324Z"
        fill="#A5B3C5"
      />
      <path
        d="M110.905 112.324H45.022C43.9053 112.324 43 111.418 43 110.302C43 109.185 43.9053 108.28 45.022 108.28H110.905C112.022 108.28 112.927 109.185 112.927 110.302C112.928 111.418 112.022 112.324 110.905 112.324Z"
        fill="#A5B3C5"
      />
      <path
        d="M115.905 106.324H40.022C38.9053 106.324 38 105.418 38 104.302C38 103.185 38.9053 102.28 40.022 102.28H115.905C117.022 102.28 117.927 103.185 117.927 104.302C117.928 105.418 117.022 106.324 115.905 106.324Z"
        fill="#A5B3C5"
      />
      <path
        d="M90.9052 96.3236H65.022C63.9053 96.3236 63 95.4183 63 94.3016C63 93.1848 63.9053 92.2795 65.022 92.2795H90.9052C92.022 92.2795 92.9273 93.1848 92.9273 94.3016C92.9275 95.4183 92.0223 96.3236 90.9052 96.3236Z"
        fill="#A5B3C5"
      />
      <path
        d="M72.2842 65H53.7158C52.7682 65 52 64.1046 52 63C52 61.8954 52.7682 61 53.7158 61H72.2842C73.2318 61 74 61.8954 74 63C74.0002 64.1046 73.2321 65 72.2842 65Z"
        fill="#A5B3C5"
      />
      <path
        d="M57.2501 58H53.7499C52.7835 58 52 57.1046 52 56C52 54.8954 52.7835 54 53.7499 54H57.2501C58.2165 54 59 54.8954 59 56C59 57.1043 58.2165 58 57.2501 58Z"
        fill="#A5B3C5"
      />
      <path
        d="M75.3109 71H53.6891C52.7562 71 52 70.1046 52 69C52 67.8954 52.7562 67 53.6891 67H75.3109C76.2438 67 77 67.8954 77 69C77.0002 70.1046 76.244 71 75.3109 71Z"
        fill="#A5B3C5"
      />
      <path
        d="M69.3243 77H53.6757C52.7502 77 52 76.1046 52 75C52 73.8954 52.7502 73 53.6757 73H69.3243C70.2498 73 71 73.8954 71 75C71 76.1046 70.25 77 69.3243 77Z"
        fill="#A5B3C5"
      />
    </svg>
  );
});
export { ModalIconConstructor };
