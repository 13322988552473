import styled from 'styled-components';
import { respondTo, transition } from '@assets/style/tools/mixin';
import { variables } from '@assets/style/tools/variables';
import Modal from '@ant/Modal';
import { IconChackMark } from '@icons/Svg/IconChackMark';
import { ModalIconConstructor } from '@icons/Svg/ModalIconConstructor';
import { Button } from '../Button';

const Style = {
  Modal: styled(Modal)`
    .ant-modal-content {
      max-width: ${({ changeUiWithIcon }) => (changeUiWithIcon ? '500px' : '400px')};
      display: grid;
      height: auto;
      margin: 0 auto;
      border-top: ${({ changeUiWithIcon }) => (changeUiWithIcon ? 'none' : `6px solid ${variables.accent}`)};
      ${respondTo(`xsm`, `max-width: 90%;`)}
    }
    .ant-modal-body {
      padding: ${({ changeUiWithIcon }) => (changeUiWithIcon ? '56px' : '40px')};
      ${respondTo(`xsm`, `padding : 20px 15px;`)}
    }
    .ant-modal-close {
      top: 20px;
      right: 20px;
      svg {
        ${transition()};
        fill: #b2c7d9;
      }
      &:hover {
        svg {
          fill: ${variables.black};
        }
      }
    }
    .ant-modal-close-x {
      width: 12px;
      height: 12px;
      line-height: 12px;
      .anticon {
        vertical-align: 0;
      }
    }
  `,
  InnerContainer: styled.div`
    text-align: center;
  `,
  Title: styled.p`
    font-weight: bold;
    font-size: ${({ changeUiWithIcon }) => (changeUiWithIcon ? '24px' : '18px')};
    line-height: 120%;
    color: ${variables.titleText};
    justify-self: center;
    padding: ${({ changeUiWithIcon }) => (changeUiWithIcon ? '45px 0 16px' : '18px 0 10px')};
  `,
  Description: styled.p`
    font-size: 14px;
    line-height: 20px;
    color: ${variables.black};
  `,
  ButtonContainer: styled.div`
    display: inline-grid;
    grid-column-gap: 20px;
    grid-template-columns: 1fr;
    justify-content: center;
    padding-top: 35px;
  `,
  ModalIconConstructor: styled(ModalIconConstructor)``,
  Button: styled(Button)`
    color: ${variables.white};
    background: ${variables.accent};
    font-weight: 600;
    font-size: 14px;
    line-height: 140%;
    padding: 10px 34px;
    ${transition()};
  `,
  IconChackMark: styled(IconChackMark)`
    height: 40px;
  `,
};
export { Style };
