import styled from 'styled-components';
import { variables } from '@assets/style/tools/variables';
import { Form } from 'formik';
import { respondTo, flex, transition } from '@assets/style/tools/mixin';
import Button from 'antd/lib/button';

const checkForm = (props) => {
  switch (props.form_name) {
    case 'form_home_page': {
      return `
        max-width: 564px;
      `;
    }
    case 'form_about_us': {
      return `
      flex-wrap: wrap;
      ${respondTo(`md`, `justify-content: start;`)}
      ${respondTo(`mds`, `max-width: 290px; margin: 0 auto;`)};
      ${respondTo(`sm`, `max-width: 100%; flex-wrap: nowrap;`)};
      ${respondTo(`xsm-s-420`, `flex-wrap: wrap;`)}
      label {
        grid-template-rows: auto 57px;
        ${respondTo(`mds`, `grid-template-rows: auto 44px;`)};

      }
      input {
        max-width: 100%;
        height: 57px;
        ${respondTo(`mds`, `height: 44px;`)};
      }
      button {
        margin-top: 18px;
        max-width: 225px;
        ${respondTo(`mds`, `max-width: 100%; margin-top: 10px;`)};
        ${respondTo(`sm`, `margin-top: 0;`)};
        ${respondTo(`xsm-s-420`, `margin-top: 10px;`)}
      }
     
  `;
    }
    case 'form_blog':
    case 'form_news':
    case 'form_cases': {
      return `
        display: grid;
        grid-template-columns: auto 180px;
        height: 44px;
        justify-content: center;
        ${respondTo(`1180px`, `grid-template-columns: auto 170px; max-width: 420px; margin: 0 auto;`)}
        ${respondTo(`840px`, `grid-template-columns: auto 140px;`)}
        ${respondTo(
          `sm`,
          `grid-template-columns: auto 170px; max-width: none; margin: inherit; justify-content: center;`,
        )}
        ${respondTo(`xsm`, `grid-template-columns: auto 140px;`)}
        ${respondTo(`440px`, `grid-template-columns: 1fr; grid-row-gap: 20px; height: auto;`)}

        input {
          width: 100%;
          box-sizing: border-box;
          border-radius: 1px 0px 0px 1px;
          padding-left: 20px;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          &::placeholder {
            color: #A5B3C5;
          }
          ${respondTo('440px', 'height: 52px; ')};
        }
        label {
          ${respondTo(`440px`, `grid-template-rows: auto 52px;`)};
  
        }
        button {
          margin-top: 0;
          ${respondTo('440px', 'height: 44px; max-width: 170px; margin: 0 auto;')};
        }
      `;
    }

    default:
      break;
  }
};
const checkItem = (props) => {
  switch (props.form_name) {
    case 'form_home_page': {
      return `
        max-width: 35%;
      `;
    }
    case 'form_about_us': {
      return `
        max-width: 45%;
        ${respondTo(`mds`, `max-width: 100%;`)};
        ${respondTo(`sm`, `max-width: 170px;`)};
        ${respondTo(`xsm-s-420`, `max-width: 100%;`)}
      }
      :first-child {
        max-width: 100%;
        position: relative;
        ${respondTo(`sm`, `max-width: 100%; margin-right: 4px;`)};
        ${respondTo(`xsm-s-420`, `margin-right: 0;`)}
        &:after {
          content: '';
          position: absolute;
          top: 15px;
          right: 12px;
          width: 34px;
          height: 26px;
          background: url('/img/about-us/mail.svg');
          z-index: 1;
          ${respondTo(`mds`, `display: none;`)};
        }
      }
  `;
    }
    case 'form_blog':
    case 'form_news':
    case 'form_cases': {
      return `
        ${respondTo(`hds`, `max-width: 100%;`)}
        ${respondTo(`md`, `max-width: 100%;`)};
        ${respondTo(`sm`, `max-width: 100%;`)}
        ${respondTo(`xsm-s`, `max-width: 100%;`)};
        ${respondTo('440px', 'text-align: center;')};
        ${respondTo(`xss-s`, `max-width: 100%;`)};
        :first-child {
          max-width: 100%;
        }`;
    }
    default:
      break;
  }
};

const Style = {
  Form: styled(Form)`
    max-width: 564px;
    ${flex(`space-between`, `flex-start`)}
    ${respondTo(`hds`, `max-width: 515px;`)}
    ${respondTo(`md`, `flex-wrap: wrap; justify-content: center;`)};
    ${respondTo(`sm`, `margin-left: auto; margin-right: auto; justify-content: space-between;`)}
    ${respondTo(`xsm-s`, `justify-content: center;`)};
   
    label {
      grid-template-rows: auto 44px;
    }
    input {
      height: 44px;
      font-family: ${variables.font};
      font-weight: 500;
      font-size: 14px;
      line-height: 15px;
      color: ${variables.black};
      max-width: 360px;
      padding: 14px 26px 13px 16px;
      border: 1px solid ${variables.border};
      background: ${variables.white};
      border-radius: 1px 0 0 1px;
      &:hover {
        border: 1px solid ${variables.grey};
        border-radius: 1px;
        box-shadow: none;
      }
      &:focus {
        border: 1px solid ${variables.accent};
        box-shadow: none;
      }
      &::placeholder {
        font-family: ${variables.font};
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: ${variables.black};
      }
    }
    ${(props) => checkForm(props)};
  `,
  Item: styled.div`
    width: 100%;
    margin-bottom: 0;
    ${respondTo(`hds`, `max-width: 33%;`)}
    ${respondTo(`md`, `max-width: 290px;`)};
    ${respondTo(`sm`, `max-width: 33%;`)}
    ${respondTo(`xsm-s`, `max-width: 290px;`)};
    ${respondTo(`xss-s`, `max-width: 100%;`)};
    &:first-child {
      max-width: 64%;
      ${respondTo(`hds`, `max-width: 66%;`)}
      ${respondTo(`md`, `max-width: 290px; margin-bottom: 10px;`)};
      ${respondTo(`sm`, `max-width: 66%;`)}
      ${respondTo(`xsm-s`, `max-width: 290px;`)};
      ${respondTo(`xss-s`, `max-width: 100%;`)};
    }
    ${(props) => checkItem(props)};
  `,
  Button: styled(Button)`
    font-family: ${variables.font};
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: ${variables.white};
    width: 100%;
    max-width: 200px;
    background: ${variables.accent};
    border-radius: 1px;
    box-shadow: none;
    padding: 12px 10px;
    height: auto;
    border: none;
    ${transition()};
    ${respondTo(`md`, `max-width: 100%; margin-top: 10px;`)};
    ${respondTo(`sm`, `margin-top: 0;`)}
    ${respondTo(`xsm-s`, `margin-top: 10px;`)};
    &:hover {
      background: ${variables.accentHover};
      color: ${variables.white};
    }
    &:active {
      background: ${variables.accentActive};
      color: ${variables.white};
    }
    &:focus {
      background: ${variables.accent};
      color: ${variables.white};
    }
  `,
};

export { Style };
