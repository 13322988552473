import React from 'react';
import PropTypes from 'prop-types';
import { Style } from './style';

const ThankYouModal = ({ title, description, textButton, modalSuccess, setModalSuccess, callback, withIcon, icon }) => {
  const closeModal = () => {
    setModalSuccess(false);
    callback && typeof callback === 'function' && callback();
  };
  return (
    <Style.Modal
      open={modalSuccess}
      onCancel={closeModal}
      centered
      title={false}
      footer={null}
      changeUiWithIcon={withIcon}>
      <Style.InnerContainer>
        {withIcon ? icon : <Style.IconChackMark />}
        <Style.Title changeUiWithIcon={withIcon}>{title}</Style.Title>
        {description && <Style.Description>{description}</Style.Description>}
        {textButton && (
          <Style.ButtonContainer>
            <Style.Button onClick={closeModal}>{textButton}</Style.Button>
          </Style.ButtonContainer>
        )}
      </Style.InnerContainer>
    </Style.Modal>
  );
};

ThankYouModal.defaultProps = {
  withIcon: false,
  icon: <Style.ModalIconConstructor />,
};

ThankYouModal.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  textButton: PropTypes.string,
  modalSuccess: PropTypes.bool,
  setModalSuccess: PropTypes.func,
  callback: PropTypes.func,
  withIcon: PropTypes.bool,
  icon: PropTypes.element,
};

export { ThankYouModal };
