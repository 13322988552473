import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { layoutStoreActions } from 'store/layoutStore';
import { Style } from 'shared/modules/ErrorModal/style';

const ErrorModal = ({ errorModal, setModalError, clearPostSubscribe, title='', description='', textButton }) => {
  const dispatch = useDispatch();
  const clearMessage = () => dispatch(layoutStoreActions.errorModalState.clear());

  useEffect(() => {
    return () => {
      clearMessage();
    };
  }, []);

  const closeModal = () => {
    clearMessage();
    setModalError(false);
    clearPostSubscribe();
  };

  return (
    <Style.Modal open={errorModal} onCancel={closeModal} centered title={false} footer={null}>
      <Style.InnerContainer>
        <Style.IconErrorMark />
        <Style.Title>{title}</Style.Title>
        <Style.Description>{description}</Style.Description>
        <Style.ButtonContainer>
          <Style.Button onClick={closeModal}>{textButton}</Style.Button>
        </Style.ButtonContainer>
      </Style.InnerContainer>
    </Style.Modal>
  );
};

ErrorModal.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  textButton: PropTypes.string.isRequired,
  errorModal: PropTypes.bool.isRequired,
  setModalError: PropTypes.func,
  clearPostSubscribe: PropTypes.func,
};

export default ErrorModal;
