import React, { useEffect, useState } from 'react';
import { Formik, Field } from 'formik';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import action, { loadingAndErrors } from 'store/webSubscription';
import { subscribeSchema } from '@shared/schemas/webPages/subscribeSchema';
import PropTypes from 'prop-types';
import useTranslation from 'next-translate/useTranslation';
import { ThankYouModal } from 'shared/modules/ThankYouModal';
import ErrorModal from './ErrorModal';
import { Field as SubscribeField } from '../Field';
import { Style } from './style';

const SubscribeForm = ({ form_name = 'form_footer', ...props }) => {
  const router = useRouter();
  const { asPath } = router;
  const dispatch = useDispatch();
  const { isLoading, errorMessage } = useSelector(loadingAndErrors());
  const { t } = useTranslation('common', 'error');
  const storeSubscribe = (values, callBack) => dispatch(action.postSubscribe.trigger({ ...values, callBack }));
  const clearPostSubscribe = () => dispatch(action.postSubscribe.clear());

  const [modalSuccess, setModalSuccess] = useState(false);
  const [modalError, setModalError] = useState(false);
  const submitHandler = (values, { resetForm }) => {
    storeSubscribe(values, () => {
      setModalSuccess(true);
    });
    resetForm();
  };
  useEffect(() => {
    !isLoading && errorMessage[0] && setModalError(true);
  }, [isLoading, errorMessage]);

  useEffect(() => {
    clearPostSubscribe();
  }, []);

  return (
    <>
      <Formik
        initialValues={{
          email: '',
          page: asPath,
          submit_form_id: form_name,
        }}
        validationSchema={subscribeSchema}
        enableReinitialize
        onSubmit={submitHandler}>
        {({ handleSubmit }) => {
          return (
            <Style.Form form_name={form_name}>
              <Style.Item form_name={form_name}>
                <Field
                  formik
                  name="email"
                  type="text"
                  placeholder={t('common:formSubscribe_input_placeholder')}
                  component={SubscribeField}
                />
              </Style.Item>
              <Style.Item form_name={form_name}>
                <Style.Button {...props} onClick={handleSubmit} htmlType="submit">
                  {t('common:formSubscribe_button')}
                </Style.Button>
              </Style.Item>
              <ThankYouModal
                modalSuccess={modalSuccess}
                setModalSuccess={setModalSuccess}
                title={t('common:thank_you')}
                description={t('common:formSubscribe_success_notification')}
              />
            </Style.Form>
          );
        }}
      </Formik>
      <ErrorModal
        title={t('error:errorHasOccurred')}
        description={errorMessage.toString()}
        textButton={t('error:modalContinue')}
        errorModal={modalError}
        setModalError={setModalError}
        clearPostSubscribe={clearPostSubscribe}
      />
    </>
  );
};
SubscribeForm.propTypes = {
  form_name: PropTypes.string,
};

export { SubscribeForm };
