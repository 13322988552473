import * as yup from 'yup';

const subscribeSchema = yup.object().shape({
  email: yup
    .string()
    .label('email')
    .email('common:formSubscribe_input_error')
    .required('common:formSubscribe_input_placeholder'),
});

export { subscribeSchema };
